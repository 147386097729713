@mixin location-list-mixin {
    &__title {
        font-size: 3rem;
    }
    &__item {
        position: relative;
        display: block;
        height: 0;
        margin-bottom: $grid-gutter-width;
        padding-bottom: 75%;
        background: white center center no-repeat;
        background-size: cover;
        color: white;
        text-decoration: none;
        &:hover,
        &:focus {
            color: white;
        }
        @include media-breakpoint-up(sm) {
            &--wide {
                padding-bottom: 35.87%;
            }
            &--tall {
                padding-bottom: 35.87%;
            }
        }
        @include media-breakpoint-up(lg) {
            padding-bottom: 75%;
            &--wide {
                padding-bottom: 36.468%;
            }
            &--tall {
                height: 100%;
                padding-bottom: 0;
            }
        }
    }
    h3 {
        font-size: 2rem;
        margin: 0;
    }
    &__content {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: $grid-gutter-width/2;
        text-shadow: 1px 1px 2px $dark;
    }
    @include media-breakpoint-up(xl) {
        h3 {
            font-size: 3rem;
        }
        &__content {
            padding: $grid-gutter-width;
        }
    }
}

.location-list {
    @include location-list-mixin;
}
