@import "bootstrap";

$fa-font-path: "../fontawesome/webfonts" !default;
@import "../fontawesome/scss/fontawesome";
@import "../fontawesome/scss/brands";
// @import "../fontawesome/scss/regular";
@import "../fontawesome/scss/solid";

$slider-primary: $primary !default;
@import "../../../../node_modules/bootstrap-slider/src/sass/bootstrap-slider";
@import "../../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3";

$container-limit-max-width: 1680px;

html {
    // @include media-breakpoint-up(xl) {
    //     font-size: 1.2rem;
    // }
}
body {
    overflow-y: scroll;
    background: $gray-600;
}
.container-limit {
    max-width: $container-limit-max-width;
}
.container-limit-sm {
    max-width: map-get($grid-breakpoints, "sm");
}
.container-limit-md {
    max-width: map-get($grid-breakpoints, "md");
}
.container-limit-lg {
    max-width: map-get($grid-breakpoints, "lg");
}
.container-limit-xl {
    max-width: map-get($grid-breakpoints, "xl");
}
.container-limit-xxl {
    max-width: 1500px;
}
#content {
    position: relative;
    min-height: 500px;
    background: white;
    .content-top-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 500px;
        background: $blue center center no-repeat;
        background-size: cover;
        + div {
            position: relative;
            padding-top: $grid-gutter-width*2;
        }
    }
}

.bg-blue {
    background-color: $blue!important;
}
.btn-success {
    @include button-variant($dark-green, $dark-green);
}
.btn-yellow {
    @include button-variant(#e3d17d, $yellow);
}

.text-shadow-dark {
    text-shadow: 1px 1px 2px $dark;
}
.text-shadow-white {
    text-shadow: 1px 1px 2px $white;
}

.g-recaptcha > div,
.g-recaptcha-alt > div {
    margin: 0 auto;
}

//
// Privacy Policy
// --------------------------------------------------

.privacy-policy-bar {
    position: fixed;
    z-index: 9999;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: $grid-gutter-width*2 0;
    background: $dark;
    background: rgba($dark, 0.95);
    // border-top: solid 1px $dark;
    // color: $text-color;
    color: white;
    text-align: center;

    a {
        text-decoration: underline;
    }
    button {
        // vertical-align: bottom;
    }
}

//
// Datepicker
// --------------------------------------------------

.input-daterange input,
.input-daterange input:first-child,
.input-daterange input:last-child {
    border-radius: 0;
}

.datepicker table tr td,
.datepicker table tr th {
    border-radius: 0;
}
.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active {
    background-color: $primary;
}
.datepicker table tr td.active:active:hover,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active:hover,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.highlighted.active:focus,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.highlighted.active.focus {
    background-color: darken($primary, 7.5%);
}
.datepicker table tr td span {
    border-radius: 0;
}
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
    background-color: $primary;
}
.datepicker table tr td span.active:active:hover,
.datepicker table tr td span.active:hover:active:hover,
.datepicker table tr td span.active.disabled:active:hover,
.datepicker table tr td span.active.disabled:hover:active:hover,
.datepicker table tr td span.active.active:hover,
.datepicker table tr td span.active:hover.active:hover,
.datepicker table tr td span.active.disabled.active:hover,
.datepicker table tr td span.active.disabled:hover.active:hover,
.datepicker table tr td span.active:active:focus,
.datepicker table tr td span.active:hover:active:focus,
.datepicker table tr td span.active.disabled:active:focus,
.datepicker table tr td span.active.disabled:hover:active:focus,
.datepicker table tr td span.active.active:focus,
.datepicker table tr td span.active:hover.active:focus,
.datepicker table tr td span.active.disabled.active:focus,
.datepicker table tr td span.active.disabled:hover.active:focus,
.datepicker table tr td span.active:active.focus,
.datepicker table tr td span.active:hover:active.focus,
.datepicker table tr td span.active.disabled:active.focus,
.datepicker table tr td span.active.disabled:hover:active.focus,
.datepicker table tr td span.active.active.focus,
.datepicker table tr td span.active:hover.active.focus,
.datepicker table tr td span.active.disabled.active.focus,
.datepicker table tr td span.active.disabled:hover.active.focus {
    background-color: darken($primary, 7.5%);
}

@mixin enquiry-form($main-color) {
    color: $main-color;
    .form-group {
        margin-bottom: 2rem;
    }
    label {
        margin-bottom: 0;
        padding: 0 $input-padding-x-sm;
        font-size: $font-size-lg;
        line-height: 1;
    }
    .form-control {
        height: $input-height-sm;
        padding: $input-padding-y-sm $input-padding-x-sm;
        border: none;
        border-bottom: solid 2px $main-color;
        &:focus {
            box-shadow: 0 0 0 $input-btn-focus-width rgba($main-color, .25);
        }
        &.is-invalid {
            border-color: $danger;
            &:focus {
                box-shadow: 0 0 0 $input-btn-focus-width rgba($danger, .25);
            }
        }
        &.dateinput {
            text-align: left;
        }
    }
    textarea.form-control {
        height: 160px;
    }
}

@import "header";
@import "footer";
@import "property";
@import "location";
@import "contact";
@import "home";
@import "blog";
