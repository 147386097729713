.blog-detail {
    &__header {
        position: relative;
        height: 500px;
        background: $blue center center no-repeat;
        background-size: cover;
        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 260px;

            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
            background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
        }
        &__content {
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 100%;
        }
        &__pub-date {
            font-size: $font-size-lg;
        }
        h1 {
            font-size: $display4-size;
        }
    }
    &__other-posts {
        background: $gray-300;
        h2 a {
            color: $blue;
        }
    }
}
.post-box {
    background: $gray-200;
    color: $gray-600;
    a,
    a:hover,
    a:focus {
        color: $gray-600;
    }
}
