.property-filter {
    > .row {
        margin-right: -2px;
        margin-left: -2px;
        > [class^='col-'] {
            padding-right: 2px;
            padding-left: 2px;

            @include media-breakpoint-up(lg) {
                width: auto;
                max-width: none;
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0;
                &:last-child {
                    flex-grow: 0;
                }
            }
        }
    }
    .form-group {
        position: relative;
        margin-bottom: 4px;
        background: white;
    }
    label {
        position: absolute;
        z-index: 0;
        top: 15px;
        left: 70px;
        font-family: $font-family-serif;
        font-size: $font-size-sm;
        text-transform: uppercase;
    }
    select,
    &__value {
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;
        height: 80px;
        padding: 35px 50px 15px 70px;
        font-size: $font-size-lg;
        border: none;
        background: transparent;

        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
    }
    &__value {
        font-size: $font-size-sm;
        white-space: nowrap;
        cursor: default;
    }
    &__icon {
        position: absolute;
        z-index: 0;
        top: 20px;
        left: 15px;
        width: 40px;
        color: $primary;
        font-size: 40px;
        text-align: center;
    }
    &__arr {
        position: absolute;
        z-index: 0;
        top: 28px;
        right: 15px;
        width: 40px;
        font-size: 26px;
        text-align: center;
    }
    &__slider {
        padding: 5px $grid-gutter-width/2;
        background: white;
        .slider.slider-horizontal {
            width: 100%;
        }
        .slider-selection {
            background: lighten($primary, 20%);
        }
        @include media-breakpoint-up(lg) {
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 0;
            width: 100%;
        }
    }
    &__col-submit {
        position: relative;
        height: 80px;
        float: right;
        @include media-breakpoint-up(lg) {
            float: none;
        }
    }
    &__submit {
        width: 80px;
        height: 80px;
        font-size: 40px;
    }
    &__clear {
        position: absolute;
        top: 100%;
        left: 0;
        width: 80px;
        height: auto;
        padding: 0;
        font-size: 9px;
        text-transform: uppercase;
    }
}
.property-listing {

}
.property-detail {
    &__header {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-family: $font-family-serif;
        h1 {
            font-size: $display4-size;
        }
        nav {
            // font-size: $font-size-lg;
            font-weight: bold;
            a,
            a:hover,
            a:focus {
                color: white;
            }
            a {
                display: inline-block;
                margin: 0 $btn-padding-x-sm $btn-padding-y-lg $btn-padding-x-sm;
            }
        }
        @include media-breakpoint-up(lg) {
            // position: absolute;
            // bottom: 0;
            // left: 0;
            // width: 100%;
        }
    }
    &__overview {
        padding-top: $grid-gutter-width/2;
        &__sidebar {
            &__details {
                font-family: $font-family-serif;
                i {
                    font-size: 40px;
                }
            }
            &__price {
                font-family: $font-family-serif;
                color: $gray-600;
            }
            &__plane,
            &__speak {
                position: relative;
                height: 60px;
                padding: 1.2rem $input-padding-x 1.2rem 60px;
                color: $gray-600;
                font-family: $font-family-serif;
                font-size: $font-size-sm;
                font-weight: bold;
                text-align: center;
                i {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 60px;
                    height: 60px;
                    padding: 10px;
                    color: white;
                    font-size: 40px;
                }
            }
            &__plane {
                i {
                    background: $blue;
                }
            }
            &__speak {
                i {
                    background: $primary;
                }
            }
            &__al {
                font-size: $font-size-lg;
            }
        }
        @include media-breakpoint-up(lg) {
            padding-top: $grid-gutter-width*2;
            &__sidebar {
                margin-top: -80px - $grid-gutter-width*2;
            }
        }
    }
    &__slider {
        &__slide {
            position: relative;
            cursor: pointer;
            &__overlay {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4);
                color: white;
                text-shadow: 1px 1px 1px $dark;
                font-size: $font-size-lg;
            }
        }
    }
    &__rates {
        position: relative;
        z-index: 1;
    }
    &__availability {
        &__cal {
            position: relative;
            &.busy {
                cursor: wait;
                &:before {
                    content: '';
                    position: absolute;
                    z-index: 100;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: $green;
                    background: rgba($green, 0.5);
                }
            }
        }
        &__prev,
        &__next {
            cursor: pointer;
            font-size: $h2-font-size;
        }
        h4 {
            margin: 0;
            font-weight: bold;
            font-family: $font-family-base;
            font-size: $h2-font-size;
            text-transform: uppercase;
        }
        table {
            th {
                padding: 0.75rem 0.50rem;
                font-size: 0.6rem;
                font-weight: normal;
            }
            td {
                padding: 0.25rem 0;
            }
        }
        .day,
        &__caption {
            padding: 0.50rem;
            border: solid 1px transparent;
            font-size: $font-size-sm;
            &--today {
                border: solid 1px white;
            }
            &--booked {
                background: $dark-green;
            }
        }
        @include media-breakpoint-up(sm) {
            table {
                th {
                    padding: 0.75rem;
                }
            }
            .day,
            &__caption {
                padding: 0.50rem 0.75rem;
                font-size: $font-size-base;
            }
        }
    }
    &__booking-note {
        position: relative;
        z-index: 0;
        background: $dark-green;
        @include media-breakpoint-up(md) {
            &__text {
                column-count: 2;
                column-gap: 10rem;
            }
        }
    }
    &__map {
        .google-map {
            width: 100%;
            height: 500px;
        }
    }
    &__enquiry {
        @include enquiry-form($dark-green);
    }
    &__more-properties {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 45%;
            background: $gray-300;
        }
        > div {
            position: relative;
        }
        h2 strong {
            font-weight: bold;
        }
    }
}
.property-box {
    background: $gray-200;
    color: $gray-600;
    a,
    a:hover,
    a:focus {
        color: $gray-600;
    }
    &__info-row {
        position: relative;
        margin-top: -47px;
        padding: 0 $grid-gutter-width;
        font-family: $font-family-serif;
        &__outer {
            overflow: hidden;
        }
        i {
            font-size: 30px;
        }
        &__al {
            position: absolute;
            top: 100%;
            right: $grid-gutter-width;
            font-family: $font-family-base;
        }
    }
}
.advanced-enquiry {
    @include enquiry-form($gray-600);

    margin-top: 10rem;
    padding: 10rem 0;
    background: bottom left url(../img/bg-yellow-circles-3.svg) no-repeat;
    background-color: $gray-200;
    background-size: 600px;

    &__check-col {
        .form-group:first-child {
            margin-bottom: 1rem;
        }
    }
    .form-control {
        background: $gray-200;
        // background: transparent;
    }
    @include media-breakpoint-up(sm) {
        background-size: contain;
    }
    @include media-breakpoint-up(xl) {
        background-position: top center;
        background-size: cover;
    }
}
