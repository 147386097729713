.header {
    position: relative;
    background: $blue center center no-repeat;
    background-size: cover;
    padding-top: 120px;
    &--h-100-mobile {
        @include media-breakpoint-down(md) {
            min-height: 568px;
            height: 100vh;
        }
    }
    &--img-bg {
        @include media-breakpoint-up(lg) {
            min-height: 720px;
            height: calc(100vh - 270px);
        }
    }
    &--submenu-shadow {
        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 260px;

            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
            background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

        }
    }
    &__bar {
        // position: fixed;
        // z-index: 1000;
        // width: 100%;
        // left: 0;
        // top: 0;
        // padding-top: $grid-gutter-width/2;
        // padding-bottom: $grid-gutter-width/2;
        // background: white;

        min-height: 120px;
        padding-top: $grid-gutter-width/2;
        padding-bottom: $grid-gutter-width/2;
        background: white;
        transition: all 1s ease;
        .header--img-bg & {
            background: rgba(255, 255, 255, 0.6);
        }
        &--small,
        .header--img-bg &--small {
            min-height: 78px;
            background: white;
            box-shadow: $box-shadow-sm;
        }
        &__logo-col {
            // background: white;
            // padding: $grid-gutter-width/2 $grid-gutter-width/2;
            img {
                max-width: 360px;
                transition: all 1s ease;
            }
        }
        &--small &__logo-col {
            img {
                max-width: 188px;
            }
        }
        @include media-breakpoint-up(xl) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__property-btn {
        border: solid 1px $primary;
        background: transparent;
        font-weight: bold;
        font-size: $font-size-base;
        line-height: 1.75;
        color: $body-color;
        text-transform: uppercase;
        &:hover {
            border-color: $primary;
            // background: $primary;
            color: white;
        }
        // .header--img-bg & {
        //     border-color: $white;
        //     color: $white;
        //     text-shadow: 1px 1px 1px $dark;
        //     &:hover {
        //         border-color: $white;
        //         background: $white;
        //         color: $secondary;
        //         text-shadow: none;
        //     }
        // }
    }
    .navbar-toggler {
        border: none;
        color: $body-color;
        padding: 0 4px;
        font-size: 30px;
        line-height: 1;
    }
    &__menu {
        width: 100%;
        margin: 1rem 0 0 0;
        text-align: center;
        font-family: $font-family-serif;
        > a,
        &__item {
            margin: 1rem $grid-gutter-width/2 0 $grid-gutter-width/2;
        }
        > a {
            display: inline-block;
        }
        &__item {
            position: relative;
        }
        a {
            font-weight: bold;
            color: $body-color;
            text-transform: uppercase;
            &:hover,
            &:focus,
            &.active {
                text-decoration: underline;
            }
            // .header--img-bg & {
            //     color: $white;
            //     text-shadow: 1px 1px 1px $dark;
            // }
        }
        .dropdown-item {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
        // @include media-breakpoint-up(sm) {
        //     text-align: right;
        // }
        @include media-breakpoint-up(md) {
            margin: 0;
            text-align: right;
        }
        @include media-breakpoint-up(xl) {
            > a,
            &__item {
                margin: 0 $grid-gutter-width/2;
            }
        }

        &__item-long {
            display: inline-block !important;
        }
        &__item-short {
            display: none !important;
        }
        @media (min-width: breakpoint-min(xl)) and (max-width: 1560px) {
            &__item-long {
                display: none !important;
            }
            &__item-short {
                display: inline-block !important;
            }
        }
    }
    &__content {
        padding: 6rem 0;
        &__title {
            color: $white;
            text-shadow: 1px 1px 2px $dark;
            text-transform: uppercase;
            text-align: center;
            font-size: 2.0rem;
        }
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            padding: 0;
            &__title {
                font-size: 3.0rem;
            }
        }
    }
    .property-filter {

    }
}
