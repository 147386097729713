.footer {
    // margin-top: 4rem;
    padding: 4rem 0;
    background: $gray-600;
    a {
        color: $gray-400;
    }
    .border-bottom {
        border-color: lighten($gray-600, 10%) !important;
    }
}
