.contact-page {
    &__contacts {
        .border {
            border-color: rgba(white, 0.3) !important;
        }
        &__main-icon {
            width: 64px;
            height: 64px;
            font-size: 1.8rem;
            border: solid 2px white;
            border-radius: 50%;
        }
        a i {
            font-size: 1.8rem;
        }
    }
    &__form-section {
        @include enquiry-form($dark);
        background-color: $gray-200;
        .form-control {
            background: $gray-200;
            // background: transparent;
        }
    }
}
