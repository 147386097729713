.home-browse {
    &__outer {
        position: relative;
        @include media-breakpoint-up(xl) {
            padding-bottom: 8rem;
        }
    }
    &__bg {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        > div,
        > div > div {
            height: 100%;
        }
        &__bbb {
            background: $yellow;
        }
        @include media-breakpoint-up(xl) {
            display: block;
        }
    }
    &__bg-2 {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;

        background: top 50% right url(../img/bg-yellow-circles.svg) no-repeat;
        background-size: 637px;
        @include media-breakpoint-up(xl) {
            display: block;
        }
    }
    &__left {
        padding-top: $grid-gutter-width;
        padding-bottom: $grid-gutter-width;
        background: white;
        h2 {
            // font-size: $display4-size;
        }
        @include media-breakpoint-up(lg) {
            h2 {
                font-size: $h1-font-size;
            }
        }
        @include media-breakpoint-up(xl) {
            padding-top: $grid-gutter-width*3;
            padding-bottom: 0;
            padding-right: 8%;
            h2 {
                // font-size: $display4-size;
                font-size: $h1-font-size;
            }
        }
    }
    &__right {
        position: relative;
        padding-bottom: 8rem;
        border-top: 2px solid $yellow;
        background: top right url(../img/bg-yellow-circles.svg) no-repeat;
        background-size: 637px;
        & > div {
            padding: $grid-gutter-width/2;

        }
        &__item {

        }
        &__icon {
            width: 60px;
            font-size: 40px;
            text-align: center;
            color: $primary;
        }
        @include media-breakpoint-up(xl) {
            padding-bottom: 0;
            background: none;
            border-top: none;
        }
    }
    i {
        display: inline-block;
    }
}

.home-featured {
    $height: 600px;

    margin-bottom: 10rem;
    overflow-x: hidden;
    &__title {
        @include media-breakpoint-up(sm) {
            font-size: 3rem;
        }
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 0;
            left: $grid-gutter-width/2;
            color: $gray-300;
            line-height: 1;
            font-size: 2.6rem;
        }
        @include media-breakpoint-up(xl) {
            font-size: 3.2rem;
        }
        @media (min-width: map-get($grid-breakpoints, "xl") + 200px) {
            font-size: 4rem;
        }
        @media (min-width: $container-limit-max-width) {
            font-size: 4.8rem;
        }
    }
    &__content {
        position: relative;
        min-height: $height;
        background: $gray-200;
    }
    &__buttons {
        position: absolute;
        z-index: 2;
        top: $grid-gutter-width;
        right: 0;
        width: 10px;
        height: $height - ($grid-gutter-width*2);
    }
    &__button {
        height: 40px;
        margin-bottom: 10px;
        background: $primary;
        opacity: 0.4;
        cursor: pointer;
        &.active {
            opacity: 1;
        }
        @include media-breakpoint-up(lg) {
            height: floor(($height - ($grid-gutter-width*2) - 20px)/3);
        }
    }
    &__item {
        display: none;
        &.active {
            display: block;
        }
        &__overlay {
            position: relative;
            z-index: 1;
            &__content {
                position: relative;
                padding: 180px $grid-gutter-width 0 $grid-gutter-width/2;
            }
        }
        &__bg {
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
        }
        &__title {
            font-size: 2rem;
            text-transform: uppercase;
            a {
                color: white;
            }
        }
        &__al {
            font-size: $font-size-sm;
            color: white;
        }
        @include media-breakpoint-down(md) {
            &__tag,
            &__title,
            &__al {
                text-shadow: 1px 1px 1px $dark;
            }
        }
        @include media-breakpoint-up(md) {
            &__overlay {
                &__content {
                    padding: 240px $grid-gutter-width*3 0 $grid-gutter-width/2;
                }
            }
            // &__title {
            //     font-size: 2rem;
            // }
        }
        @include media-breakpoint-up(lg) {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            &__overlay {
                position: absolute;
                // z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                &__content {
                    width: 40%;
                    padding: 240px $grid-gutter-width*3 0 $grid-gutter-width/2;
                }
            }
            &__bg {
                position: relative;
                // top: 0;
                // left: 0;
            }
            &__title {
                a {
                    color: $body-color;
                }
            }
            &__al {
                color: $gray-600;
            }
        }
        @include media-breakpoint-up(xl) {
            &__title {
                font-size: 2.5rem;
            }
        }
        @media (min-width: $container-limit-max-width) {
            &__title {
                font-size: 3rem;
            }
        }
    }
    &__right {
        position: relative;
        z-index: 1;
        min-height: $height;
        background: white center center no-repeat;
        background-size: cover;
        &__icons {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            i {
                font-size: 50px;
            }
            > div {
                display: inline-block;
                font-family: $font-family-serif;
                font-weight: bold;
                font-size: $font-size-sm;
            }
        }
        @include media-breakpoint-down(xs) {
            &__icons {
                font-size: 0.8rem;
                i {
                    font-size: 30px;
                }
                > div > div {
                    margin-top: 0.5rem !important;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            &__icons {
                top: $grid-gutter-width;
                left: -50px;
                width: 100px;
                height: $height - ($grid-gutter-width*2);
                > div {
                    display: block;
                }
            }
        }
    }
}

.home-featured-locations {
    @include location-list-mixin;

    &__item {
        &--wide {
            margin-bottom: $grid-gutter-width;
        }
        @include media-breakpoint-up(lg) {
            &--wide {
                margin-bottom: 0;
            }
        }
    }
}

.home-blog {
    position: relative;
    margin-top: 10rem;
    margin-bottom: 10rem;
    &__title {
        font-size: 3rem;
    }
    .h2 {
        font-weight: bold;
        font-size: $h5-font-size;
    }
    @include media-breakpoint-up(sm) {
        .h2 {
            font-size: $h4-font-size;
        }
    }
    @include media-breakpoint-up(md) {
        .h2 {
            font-size: $h3-font-size;
        }
    }
    @include media-breakpoint-up(lg) {
        &__title {
            position: absolute;
            z-index: 1;
            top: -60px;
            left: 0;
            line-height: 1;
            font-size: 120px;
            font-weight: bold;
            opacity: 0.05;
        }
    }
    @include media-breakpoint-up(xl) {
        .h2 {
            font-size: $h2-font-size;
        }
    }
}

.footer-services {
    margin-top: 10rem;
    padding: 8rem 0;
    background: $gray-200;
    i {
        font-size: 6rem;
    }
}
